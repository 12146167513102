import React, { useEffect, useState } from 'react'
import { ReactComponent as Down } from 'assets/down-chevron.svg'
import { motion } from 'framer-motion'
import { getPaquete } from 'actions/administracion'
import useAdmin from 'hooks/useAdmin'
import { useDispatch } from 'react-redux'

const Planes = ({ form, setForm, stepUp, stepDown, variants }) => {
  const { plan } = form
  const { paquete, isLoadingPaquete } = useAdmin()
  const dispatch = useDispatch()

  const handleSelectPlan = (plan) => {
    setForm((prev) => ({
      ...prev,
      plan: {
        option: '01',
        ...plan,
      },
    }))
  }

  useEffect(() => {
    if (isLoadingPaquete) {
      dispatch(getPaquete)
      //setPaqueteLista(paquete)
    }
  }, [paquete, dispatch, isLoadingPaquete])

  return (
    <motion.div initial={variants.initial} animate={variants.animate} exit={variants.exit} transition={variants.transition}>
      <div className="Form registroForm">
        <div className="carrusel step01">
          <div className="slide planesStep">
            <div className="steps">
              <span>Paso</span> <b>1</b> <span>de 2</span>
            </div>
            <h4>
              <button className="backButton" onClick={stepDown}>
                <Down />
              </button>
              Elige un Plan
            </h4>
            <p>Puedes seleccionar un plan de los que se ofrecen a continuación</p>

            {paquete
              ? paquete.map((plan, index) => (
                  <div className="tables">
                    <table
                      className={`tablePlanes active`}
                      onClick={() =>
                        handleSelectPlan({
                          nombre: plan.titulo,
                          mensual: plan.precio,
                          option: plan.frecuenciaPago,
                          anual: 250000,
                        })
                      }
                    >
                      <thead>
                        <tr>
                          <th className="asistido opacith">
                            <h4>{plan.titulo}</h4>
                            <h5>{plan.descripcion}</h5>
                          </th>
                        </tr>
                        <tr className="priceTable">
                          <th className="asistido" style={{ background: 'rgb(200, 97, 38)' }}>
                            <b>₡ {plan.precio}</b>
                            <span>Pago {plan.frecuenciaPago === 1 ? 'Unico' : 'Mensual'}</span>
                          </th>
                        </tr>
                        <tr>
                          <th className="asistido opacith">
                            <b>Requisitos</b>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {plan?.requisitosList.map((requisito, index) => (
                          <tr>
                            <td>{requisito}</td>
                          </tr>
                        ))}

                        {plan?.adicionalesList?.lenght > 0 ? (
                          <tr>
                            <td>{plan.adicionales}*</td>
                          </tr>
                        ) : (
                          ''
                        )}
                        <tr>
                          <th className="asistido opacith">
                            <b>Adicionales</b>
                          </th>
                        </tr>
                        {plan?.adicionalesList.map((adicional, index) => (
                          <tr>
                            <td>{adicional}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))
              : ''}
          </div>
        </div>
      </div>

      <div className="actionFixed">
        <button className="btnRegistro btnPlanes" onClick={stepUp} disabled={!plan.nombre}>
          Confirmar Plan
        </button>
      </div>
    </motion.div>
  )
}

export default Planes
