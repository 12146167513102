import { getTiposDocs } from 'actions/misc'
import axios from 'axios'
import Text from 'components/FormField/Text'
import { fileToBase64 } from 'helpers/files'
import useMisc from 'hooks/useMisc'
import useUser from 'hooks/useUser'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getInstance } from 'api/apiInstance'

const DetallesGenerales = ({ company, setCompany }) => {
  const { isEdit } = company ?? {}
  const dispatch = useDispatch()
  const { tiposDocs } = useMisc()
  const { savetaxInfo } = useUser()

  const [paises, setPaises] = useState([])
  const [provincias, setProvincias] = useState([])
  const [cantones, setCantones] = useState([])
  const [distritos, setDistritos] = useState([])
  const [barrios, setBarrios] = useState([])

  const [loadingRegiones, setLoadingRegiones] = useState(true)

  useEffect(() => {
    if (!tiposDocs.length) {
      dispatch(getTiposDocs(savetaxInfo.cia.codCia))
    }
  }, [tiposDocs, dispatch, savetaxInfo])

  const handleChange = (event) => {
    const { value, name } = event.target
    setCompany({ ...company, [name]: value })
  }

  useEffect(() => {
    setLoadingRegiones(true)
    const fetchPaises = async () => {
      const request = await getInstance()
      const paises = await request.get('pais')
      setPaises(paises?.data)
      setLoadingRegiones(false)
    }
    fetchPaises()
  }, [])

  useEffect(async () => {
    if (company?.pais?.idPais) {
      setLoadingRegiones(true)

      const request = await getInstance()
      await request.get(`departamento/find-by-pais/${company?.pais?.idPais}`).then((res) => {
        setProvincias(res?.data)
        setLoadingRegiones(false)
      })
    }
  }, [savetaxInfo, company?.pais?.idPais])

  useEffect(async () => {
    if (company?.departamento?.idDepartamento) {
      setLoadingRegiones(true)

      const request = await getInstance()
      await request.get(`municipio/find-by-depto/${company?.departamento?.idDepartamento}`).then((res) => {
        setCantones(res?.data)
        setLoadingRegiones(false)
      })
    }
  }, [savetaxInfo, company?.departamento?.idDepartamento])

  useEffect(async () => {
    if (company?.idMunicipio?.idMunicipio) {
      setLoadingRegiones(true)
      const request = await getInstance()
      await request
        .get(`distrito/find-by-municipio/${company?.idMunicipio?.idMunicipio ?? savetaxInfo?.idMunicipio?.idMunicipio}`)
        .then((res) => {
          setDistritos(res?.data)
          setLoadingRegiones(false)
        })
    }
  }, [savetaxInfo, company?.idMunicipio?.idMunicipio])

  useEffect(async () => {
    if (company?.distrito?.idDistrito) {
      setLoadingRegiones(true)
      const request = await getInstance()
      await request
        .get(`barrio/find-by-distrito/${company?.distrito?.idDistrito ?? savetaxInfo?.distrito?.idDistrito}`)
        .then((res) => {
          setBarrios(res?.data)
          setLoadingRegiones(false)
        })
    }
  }, [savetaxInfo, company?.distrito?.idDistrito])

  return (
    <div className="formCompaniaDetalles">
      <Text  name="nombreCia" label="* Nombre comercial" value={company?.nombreCia ?? ''} onChange={handleChange} />
      <Text  name="razonSocial" label="* Razón social" value={company?.razonSocial ?? ''} onChange={handleChange} />
      <Text  name="ruc" label="* Número de identifiación" value={company?.ruc ?? ''} onChange={handleChange} />

      <label htmlFor="Tipodocumento" className="text">
        * Tipo documento
        <select
          
          id="Tipodocumento"
          name="tipoDoctoPersona"
          value={company?.tipoDoctoPersona?.codigoSri ?? ''}
          onChange={(e) => {
            setCompany((prev) => ({
              ...prev,
              tipoDoctoPersona: !e.target.value
                ? {}
                : {
                    ...JSON.parse(e.target[e.target.selectedIndex].dataset.doc),
                  },
            }))
          }}
        >
          <option value="">Seleccione</option>
          {tiposDocs.map((doc) => (
            <option data-doc={JSON.stringify(doc)} key={doc.codigoSri} value={doc.codigoSri}>
              {doc.nombreDoctoPersona}
            </option>
          ))}
        </select>
      </label>
      
      <Text
        name="correoElectronico"
        label="* Correo Electrónico"
        className="span2"
        
        value={company?.correoElectronico ?? ''}
        onChange={handleChange}
      />

      <label htmlFor="País" className={`text ${company?.pais?.idPais === 1 ? 'span2' : 'span3'}`}>
        * País
        <select
          disabled={loadingRegiones}
          id="País"
          name="pais"
          
          value={company?.pais?.idPais ?? ''}
          onChange={(e) => {
            setCompany((prev) => ({
              ...prev,
              pais: !e.target.value
                ? {}
                : {
                    ...JSON.parse(e.target[e.target.selectedIndex].dataset.pais),
                  },
            }))
          }}
        >
          <option value="">Seleccione</option>
          {paises.map((p) => (
            <option data-pais={JSON.stringify(p)} key={p.idPais} value={p.idPais}>
              {p.nombrePais}
            </option>
          ))}
        </select>
      </label>

      {company?.pais?.idPais === 1 && (
        <>
          <label htmlFor="Provincia" className="text">
            * Provincia
            <select
              
              disabled={loadingRegiones}
              name="departamento"
              id="Provincia"
              value={company?.departamento?.idDepartamento ?? ''}
              onChange={(e) => {
                setCompany((prev) => ({
                  ...prev,
                  departamento: !e.target.value
                    ? {}
                    : {
                        ...JSON.parse(e.target[e.target.selectedIndex].dataset.provincia),
                      },
                }))
              }}
            >
              <option value="">Seleccione</option>
              {provincias.map((provincia) => (
                <option
                  data-provincia={JSON.stringify(provincia)}
                  key={provincia.idDepartamento}
                  value={provincia.idDepartamento}
                >
                  {provincia.nombreDepartamento}
                </option>
              ))}
            </select>
          </label>

          <label htmlFor="Cantón" className="text">
            * Cantón
            <select
              
              disabled={loadingRegiones}
              name="idMunicipio"
              id="Cantón"
              value={company?.idMunicipio?.idMunicipio ?? ''}
              onChange={(e) => {
                setCompany((prev) => ({
                  ...prev,
                  idMunicipio: !e.target.value
                    ? {}
                    : {
                        ...JSON.parse(e.target[e.target.selectedIndex].dataset.municipio),
                      },
                }))
              }}
            >
              <option value="">Seleccione</option>
              {cantones.map((c) => (
                <option data-municipio={JSON.stringify(c)} key={c.idMunicipio} value={c.idMunicipio}>
                  {c.nombreMunicipio}
                </option>
              ))}
            </select>
          </label>

          <label htmlFor="Distrito" className="text">
            * Distrito
            <select
              
              disabled={loadingRegiones}
              name="distrito"
              id="Distrito"
              value={company?.distrito?.idDistrito ?? ''}
              onChange={(e) => {
                setCompany((prev) => ({
                  ...prev,
                  distrito: !e.target.value
                    ? {}
                    : {
                        ...JSON.parse(e.target[e.target.selectedIndex].dataset.distrito),
                      },
                }))
              }}
            >
              <option value="">Seleccione</option>
              {distritos.map((d) => (
                <option key={d.idDistrito} value={d.idDistrito} data-distrito={JSON.stringify(d)}>
                  {d.nombre}
                </option>
              ))}
            </select>
          </label>

          <label htmlFor="Barrio" className="text">
            * Barrio
            <select
              disabled={loadingRegiones}
              name="barrio"
              id="Barrio"
              value={company?.barrio?.idBarrio ?? ''}
              onChange={(e) => {
                setCompany((prev) => ({
                  ...prev,
                  barrio: !e.target.value
                    ? {}
                    : {
                        ...JSON.parse(e.target[e.target.selectedIndex].dataset.barrio),
                      },
                }))
              }}
            >
              <option value="">Seleccione</option>
              {barrios.map((b) => (
                <option data-barrio={JSON.stringify(b)} key={b.idBarrio} value={b.idBarrio}>
                  {b.nombre}
                </option>
              ))}
            </select>
          </label>
        </>
      )}

      <Text
        
        name="direccion"
        label="* Dirección"
        className="span2"
        value={company?.direccion ?? ''}
        onChange={handleChange}
      />
      <Text name="valIva" label="Impuesto Valor Agregado" value={company?.valIva ?? ''} onChange={handleChange} />
      <Text  name="telefonos" label="* Teléfono" value={company?.telefonos ?? ''} onChange={handleChange} />
      <Text name="celular" label="Celular" value={company?.celular ?? ''} onChange={handleChange} />
      <Text name="fax" label="Fax" value={company?.fax ?? ''} onChange={handleChange} />
      <Text name="agenciaAduanera" label="Agencia Aduanera" value={company?.representanteLegal ?? ''} onChange={handleChange} />
      <Text
        name="nIdentificacionAgenciaAduanera"
        label="N. Identificación Agencia Aduanera"
        value={company?.identificacionRepresentante ?? ''}
        onChange={handleChange}
      />

      {!isEdit && (
        <label htmlFor="cuentaContador" className="checkbox">
          Crear Cuenta Contador
          <input type="checkbox" id="cuentaContador" />
        </label>
      )}

      <label htmlFor="logo" className="text span3">
        Cargar Logo
        <input
          accept="image/*"
          name="logo"
          type="file"
          id="logo"
          onChange={async (e) => {
            const base64File = await fileToBase64(e.target.files[0])
            setCompany((prev) => ({
              ...prev,
              nombreLogo: base64File,
            }))
          }}
        />
      </label>
    </div>
  )
}

export default DetallesGenerales
