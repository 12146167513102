import { nanoid } from '@reduxjs/toolkit'
import aesjs from 'aes-js'
import axios from 'axios'
import JSEncrypt from 'jsencrypt'
import { DateTime } from 'luxon'

const secret = process.env.REACT_APP_GREEN_PAY_SECRET
const merchantId = process.env.REACT_APP_GREEN_PAY_MERCHANT
const terminal = process.env.REACT_APP_GREEN_PAY_TERMINAL

const merchantClient = axios.create({
  baseURL: 'https://sandbox-merchant.greenpay.me',
})

const checkoutClient = axios.create({
  baseURL: 'https://sandbox-checkout.greenpay.me',
})


const generateAESPairs = () => {
  const key = []
  let iv = 0
  for (let k = 0; k < 16; k++) {
    key.push(Math.floor(Math.random() * 255))
  }
  for (let k = 0; k < 16; k++) {
    iv = Math.floor(Math.random() * 255)
  }
  return {
    k: key,
    s: iv,
  }
}

const pack = (obj, pair_) => {
  const encrypt = new JSEncrypt()
  encrypt.setPublicKey(process.env.REACT_APP_GREEN_PAY_PUBLIC_KEY)

  const pair = pair_ !== undefined ? pair_ : generateAESPairs()
  const textBytes = aesjs.utils.utf8.toBytes(JSON.stringify(obj))
  const aesCtr = new aesjs.ModeOfOperation.ctr(pair.k, new aesjs.Counter(pair.s))
  const encryptedBytes = aesCtr.encrypt(textBytes)
  const encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes)
  const returnObj = {
    ld: encryptedHex,
    lk: encrypt.encrypt(JSON.stringify(pair)),
  }
  return returnObj
}

export const encryptCard = (cc) => {
  const card = {
    card: {
      cardHolder: cc.name,
      expirationDate: {
        month: Number(cc.expiry.split('-')[1]),
        year: Number(cc.expiry.split('-')[0]),
      },
      cardNumber: cc.number.replace(/ /g, ''),
      cvc: cc.cvc,
      nickname: cc.name,
    },
  }

  return pack(card, undefined)
}

export const createTokenizationOrder = (uuid) => {
  const body = {
    secret,
    merchantId,
    requestId: uuid,
  }

  const data = merchantClient.post('/tokenize', body).then((res) => res.data)

  return data
}

export const createCardToken = async (uuid, cc) => {
  const tokenOrder = await createTokenizationOrder(uuid)
  const { session, token } = tokenOrder

  const body = {
    ...cc,
    session,
  }

  const data = checkoutClient
    .post('/tokenize', body, {
      headers: {
        'liszt-token': token,
      },
    })
    .then((res) => res.data.result)

  return data
}

export const createSubscription = (ccToken, plan) => {
  const planKey = +plan.option === 2 ? 'anual' : 'mensual'
  const planRecurrency = +plan.option === 2 ? 12 : 1
  const planAmount = plan[planKey]

  const body = {
    secret,
    merchantId,
    terminal,
    userId: nanoid(),
    description: 'First subscription',
    currency: 'CRC', // change to 'CRC' when credentials are ready,
    tokens: [ccToken],
    subscription: [
      {
        startDate: DateTime.now().toMillis(),
        amount: planAmount,
        cadence: {
          mode: 'EVERY',
          unit: 'MONTH',
          every: planRecurrency,
        },
      },
    ],
  }

  const response = merchantClient.post('/subscriptions', body).then((res) => res.data)

  return response
}
