import { deleteUsuario, getCiaByCodigo, getUsuarios } from 'actions/administracion'
import { setUser } from 'actions/users'
import { ReactComponent as Create } from 'assets/create.svg'
import { ReactComponent as Delete } from 'assets/delete.svg'
import { ReactComponent as Edit } from 'assets/edit.svg'
import { ReactComponent as Search } from 'assets/search.svg'
import { ReactComponent as Updated } from 'assets/updated.svg'
import Pagination from 'components/common/Pagination'
import { createAlertConfirm } from 'components/TopBar/UserModal/UserModal'
import { IS_ON_EXCHANGE } from 'constants/actionTypes'
import useAdmin from 'hooks/useAdmin'
import useUser from 'hooks/useUser'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import FormCompania from './FormCompania/FormCompania'
import { useNavigate } from 'react-router-dom'
import './Companias.css'
import { getDatosGeograficos } from 'actions/misc'
import { saveAs } from 'file-saver'
import { getURL } from 'api/apiInstance'

const Companias = () => {
  const dispatch = useDispatch()
  const { savetaxInfo } = useUser()
  const { sucursales, usuarios, isLoadingUsuarios } = useAdmin()
  const navigate = useNavigate()

  const [currentPage, setCurrentPage] = useState(1)
  const [filtro, setFiltro] = useState('')
  const [usuariosFiltrados, setUsuariosFiltrados] = useState(sucursales)
  const [selectedCompany, setSelectedCompany] = useState(undefined)
  const [active, setActive] = useState(false)

  const handleChangeCia = async (user) => {
    dispatch({ type: 'RESET' })
    dispatch({
      type: IS_ON_EXCHANGE,
      payload: true,
    })
    dispatch(setUser(user)).then(() => {
      navigate('/')
      toast.success('Compañía cambiada correctamente')
    })

    dispatch(getDatosGeograficos)
  }

  const changeCia = (user, event) => {
    createAlertConfirm(
      `¿Estás seguro de cambiar a ${user.nombreCia}?`,
      `Podras acceder a la informacion de ${user.nombreCia}`,
      () => handleChangeCia(user)
    )
  }

  const clearFilter = () => {
    setFiltro('')
    /*const cia = savetaxInfo?.cia?.codCia

    if (isLoadingUsuarios && cia) {
      dispatch(getUsuarios)
      console.log('Entro')*/

    setUsuariosFiltrados(usuarios)
  }

  const handleFilter = (event) => {
    const value = event.target.value
    setFiltro(value)
    setUsuariosFiltrados(
      value
        ? usuarios.filter(
          (user) => user.nombreCia.toUpperCase().includes(value.toUpperCase()) || user.ruc.toString().includes(value)
        )
        : usuarios
    )
  }

  const handleOnClose = () => {
    setActive(false)
    setSelectedCompany(undefined)

    const isAdmin = savetaxInfo?.usuarioRolList[0].rol.nombreRol === 'ADMINISTRADOR'

    const cia = savetaxInfo?.cia?.codCia
    if (isLoadingUsuarios && cia) {
      if (isAdmin) {
        dispatch(getUsuarios)
      } else {
        dispatch(getCiaByCodigo(cia))
      }
    }
  }

  useEffect(() => {

    const isAdmin = savetaxInfo?.usuarioRolList[0].rol.nombreRol === 'ADMINISTRADOR'

    const cia = savetaxInfo?.cia?.codCia

    if (isLoadingUsuarios && cia) {
      if (isAdmin) {
        dispatch(getUsuarios)
      } else {
        dispatch(getCiaByCodigo(cia))
      }
    }
    setUsuariosFiltrados(usuarios)
  }, [usuarios, savetaxInfo, dispatch, isLoadingUsuarios])

  const actualizarUsuarios = async () => {
    const isAdmin = savetaxInfo?.usuarioRolList[0].rol.nombreRol === 'ADMINISTRADOR'

    const cia = savetaxInfo?.cia?.codCia
    if (isLoadingUsuarios && cia) {
      if (isAdmin) {
        dispatch(getUsuarios)
      } else {
        dispatch(getCiaByCodigo(cia))
      }
    }
    //dispatch(getUsuarios)
  }
  return (
    <>
      <div className="Sucursales">
        <div className="navSection">
          <b className="title">Filtra Por:</b>

          <label htmlFor="" style={{ width: '70%' }}>
            Nombre o Nro Identificacion
            <div className="search">
              <input
                type="text"
                name="search"
                placeholder="Nombre o Nro de Identificación"
                value={filtro}
                onChange={handleFilter}
              />
              <button>
                <Search />
              </button>
            </div>
          </label>

          <div className="sortBy">
            <label htmlFor="" style={{ width: '150px' }}>
              Limpiar Filtros
              <button onClick={clearFilter}>Limpiar</button>
            </label>
          </div>

          <button className="addContent" onClick={() => setActive(true)}
            style={{ display: savetaxInfo?.usuarioRolList[0].rol.nombreRol === 'ADMINISTRADOR' ? 'yes' : 'none' }}>
            Agregar Compañía
            <Create />
          </button>
        </div>

        <div className="listSection">
          <div className="headList">
            <ul>
              <li className="col-1">
                <b>Codigo</b>
              </li>
              <li className="col-4">
                <b>Nombre</b>
              </li>
              <li className="col-2">
                <b>Nro Identificacion</b>
              </li>
              <li className="col-3">
                <b>Correo</b>
              </li>
              <li className="col-1">
                <b></b>
              </li>
            </ul>
          </div>

          {isLoadingUsuarios ? (
            <div className="loadContentTable">
              <div className="snippet"></div>
              <span>Cargando compañías</span>
            </div>
          ) : (
            <div className="bodyList">
              <ul>
                {usuariosFiltrados?.length ? (
                  usuariosFiltrados.slice(50 * currentPage - 50, 50 * currentPage).map((user, index) => (
                    <li className="itemBodyList" key={index}>
                      <ul className="contentItemBodyList">
                        <li className="col-1">
                          <b>{user?.codCia}</b>
                        </li>
                        <li className="col-4">
                          <span>{user?.nombreCia}</span>
                        </li>
                        <li className="col-2">
                          <span>{user?.ruc}</span>
                        </li>
                        <li className="col-3">
                          <span>{user?.correoElectronico}</span>
                        </li>
                        <li className="col-1">
                          <button
                            className="btnEditItem"
                            onClick={() => {
                              setActive(true)
                              setSelectedCompany({
                                ...user,
                                isEdit: true,
                              })
                            }}
                          >
                            <Edit />
                          </button>
                          <button
                            style={{ display: savetaxInfo?.usuarioRolList[0].rol.nombreRol === 'ADMINISTRADOR' ? 'yes' : 'none' }}
                            className="btnDelete"
                            onClick={() => {
                              createAlertConfirm('Eliminar Compañías', '¿Está seguro de querer eliminar esta compañía?', () =>
                                dispatch(deleteUsuario(user))
                              )
                            }}
                          >
                            <Delete />
                          </button>

                          <button to="/" className="btnEditItem" onClick={(event) => changeCia(user, event)}
                            style={{ display: savetaxInfo?.usuarioRolList[0].rol.nombreRol === 'ADMINISTRADOR' ? 'yes' : 'none' }}>
                            <Updated />
                          </button>

                          <button
                            style={{ display: savetaxInfo?.usuarioRolList[0].rol.nombreRol === 'ADMINISTRADOR' ? 'yes' : 'none' }}
                            className="btnEditItem"
                            onClick={() => saveAs(`${getURL('fe')}cia/descargar-documentos/` + user.codCia, ` ${user.ruc}.zip`)}
                          >
                            <i className="bi bi-file-earmark-text"></i>
                          </button>
                        </li>
                      </ul>
                    </li>
                  ))
                ) : (
                  <li className="emptyResultTable">
                    <span>No hay Resultados</span>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>

        {!isLoadingUsuarios && <Pagination currentPage={currentPage} setPage={setCurrentPage} data={usuariosFiltrados} />}
      </div>

      {active && <FormCompania onClose={handleOnClose} selectedCompany={selectedCompany} actualizarUsuarios={actualizarUsuarios} />}
    </>
  )
}

export default Companias
